import React from 'react'

import { useRosterData } from '../hooks/use-roster-data'
import Twitter from './svgs/twitter'
import Instagram from './svgs/instagram'

import './roster.scss'

export default () => {
    const { edges } = useRosterData()

    const RosterList = edges.map((person) =>
        <li key={person.node.id}>
            <picture>
                <source srcSet={person.node.photo.localFile.childImageSharp.resolutions.srcSetWebp} type="image/webp" />
                <source srcSet={person.node.photo.localFile.childImageSharp.resolutions.srcSet} type="image/jpeg" /> 
                <img 
                    src={person.node.photo.localFile.childImageSharp.resolutions.src} 
                    alt={person.node.photo.title}
                    height={person.node.photo.localFile.childImageSharp.resolutions.height}
                    width={person.node.photo.localFile.childImageSharp.resolutions.width}
                    />
            </picture>

            <h3>{person.node.name}</h3>
            <ul>
                <li><a href={person.node.instagramUrl} target="_blank" rel="noopener noreferrer"><Instagram /> {person.node.instagramFollowers}</a></li>
                <li><a href={person.node.twitterUrl} target="_blank" rel="noopener noreferrer"><Twitter /> {person.node.twitterFollowers}</a></li>
            </ul>
        </li>
    )

    return (
        <section className="roster">
            <div className="wrapper">
                <ul>
                    {RosterList}
                </ul>
            </div>
        </section>
    )
}
