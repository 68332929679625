import { useStaticQuery, graphql } from "gatsby"

export const useSiteInformation = () => {
    const { contentfulSiteInformation } = useStaticQuery(
        graphql`
          query SiteInformation {
            contentfulSiteInformation {
              contactEmail
              text {
                text
              }
            }
          }        
        `
    )
  return contentfulSiteInformation
}