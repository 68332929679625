import PropTypes from 'prop-types'
import React from 'react'

import './title.scss'

const Title = ({ name, id }) => (
  <h2 id={id}><span>{name}</span></h2>
)

Title.propTypes = {
  name: PropTypes.string,
}

Title.defaultProps = {
  name: ``,
}

export default Title
