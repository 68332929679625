import { useStaticQuery, graphql } from "gatsby"

export const useRosterData = () => {
  const { allContentfulRoster } = useStaticQuery(
    graphql`
    query Roster {
        allContentfulRoster(sort: {fields: order}) {
            edges {
                node {
                    name
                    twitterFollowers
                    twitterUrl
                    instagramUrl
                    instagramFollowers
                    id
                    photo {
                        title
                        localFile {
                            childImageSharp {
                                resolutions(width: 330) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcSetWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `
  )
  return allContentfulRoster
}