import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill="#fff"
        d="M6.54 17.279c7.767 0 12.014-6.62 12.014-12.362 0-.188-.003-.375-.012-.562a8.727 8.727 0 002.107-2.25 8.235 8.235 0 01-2.425.685A4.34 4.34 0 0020.08.386c-.816.498-1.72.86-2.682 1.055A4.163 4.163 0 0014.317.068c-2.331 0-4.222 1.946-4.222 4.345 0 .34.037.672.11.99-3.51-.181-6.622-1.91-8.704-4.54a4.43 4.43 0 00-.572 2.185c0 1.507.745 2.838 1.879 3.616a4.102 4.102 0 01-1.912-.543v.055c0 2.104 1.455 3.861 3.387 4.26a4.117 4.117 0 01-1.907.074c.537 1.726 2.096 2.982 3.944 3.018a8.32 8.32 0 01-6.251 1.8 11.709 11.709 0 006.472 1.95"
      ></path>
    </svg>
  );
}

export default Twitter;
