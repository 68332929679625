import { useStaticQuery, graphql } from "gatsby"

export const useLocationsData = () => {
  const { allContentfulLocations } = useStaticQuery(
    graphql`
    query Locations {
        allContentfulLocations {
          edges {
            node {
              address
              city
              cityStateZip
              photo {
                localFile {
                  childImageSharp {
                    resolutions(grayscale: true, width: 800) {
                      srcSet
                      srcSetWebp
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `
  )
  return allContentfulLocations
}