import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill="#fff"
        d="M0 5.81c.08-.48.14-1 .26-1.45A5.3 5.3 0 015.25.1C8.7 0 12.16 0 15.61.1a5.29 5.29 0 015.14 5.21c.12 3.31.12 6.64 0 10a5.29 5.29 0 01-5.14 5.2c-3.45.14-6.91.14-10.36 0A5.42 5.42 0 01.1 15.37c0-.2-.07-.41-.1-.61V5.81zm10.45-3.89H6.16C3.49 2 2 3.41 2 6.06c-.05 2.83-.05 5.65 0 8.48 0 2.62 1.52 4.06 4.15 4.1 2.88.054 5.763.054 8.65 0 2.57 0 4-1.45 4.11-4 .07-2.9.07-5.81 0-8.71-.07-2.54-1.54-3.92-4.08-4-1.47-.04-2.92-.01-4.38-.01z"
      ></path>
      <path
        fill="#fff"
        d="M10.42 15.6a5.32 5.32 0 115.41-5.3 5.349 5.349 0 01-5.41 5.3zm3.46-5.33a3.44 3.44 0 10-3.43 3.43 3.43 3.43 0 003.43-3.43zM14.74 4.76a1.28 1.28 0 012.55 0A1.29 1.29 0 0116 6a1.28 1.28 0 01-1.26-1.24z"
      ></path>
    </svg>
  );
}

export default Instagram;
