import React from 'react'

import { useSiteInformation } from '../hooks/use-site-information'

import './contact.scss'

export default () => {
    const { contactEmail } = useSiteInformation()

    return (
        <section className="contact">
            <div className="wrapper">
                <p>Contact us at <a href="mailto:{contactEmail}" rel="noopener noreferrer" target="_blank">{contactEmail}</a></p>
            </div>
        </section>
    )
}
