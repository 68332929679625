import React from 'react'

import { useSiteInformation } from '../hooks/use-site-information'

import Circle from '../components/svgs/circle'
import Logo from '../components/svgs/logo'

import './hero.scss'

export default () => {
    const { text } = useSiteInformation()

    return (
        <section className="hero">
            <div className="wrapper">
                <div className="animated-logo">
                    <Circle height='278' width='278' className='circle' />
                    <Logo height="100" className="large-logo" />
                </div>

                <p>{text.text}</p>
            </div>
        </section>
    )
}
