import React from 'react'

import './locations.scss'
import { useLocationsData } from '../hooks/use-locations-data'

export default () => {
    const { edges } = useLocationsData()

    const locationsList = edges.map((location) =>
        <li key={location.node.id}>
            <picture>
                <source srcSet={location.node.photo.localFile.childImageSharp.resolutions.srcSetWebp} type="image/webp" />
                <source srcSet={location.node.photo.localFile.childImageSharp.resolutions.srcSet} type="image/jpeg" /> 
                <img 
                    src={location.node.photo.localFile.childImageSharp.resolutions.src} 
                    alt={location.node.city}
                    />
            </picture>

            <div className="location-overlay">
                <div>
                    <h4>{location.node.city}</h4>
                    <p>{location.node.address}</p>
                    <p>{location.node.cityStateZip}</p>
                </div>
            </div>
        </li>
    )

    return (
        <section className="locations">
            <ul>
                {locationsList}  
            </ul>
        </section>
    )
}
