import React from "react";

const Circle = props => (
  <svg fill="none" {...props}>
    <circle
        cx="139" 
        cy="139" 
        r="131.5"
        stroke="#d2d2d2"
        strokeWidth={15}
    />
  </svg>
);

export default Circle;