import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/title'

import Hero from '../components/hero'
import Roster from '../components/roster'
// import Capabilities from '../components/capabilities'
import Contact from '../components/contact'
import Locations from '../components/locations'
import Brands from '../components/brands'

const IndexPage = () => (
  <Layout>
    <SEO title='7x' />

    <Hero />

    <Title name="Roster" id="roster" />
    <Roster /> 

    {/* <Title name="Capabilities" id="capabilities" />
    <Capabilities /> */}

    <Title name="Brands" id="brands" />
    <Brands /> 

    <Title name="Contact" id="contact" />
    <Contact />
    <Locations />
  </Layout>
)

export default IndexPage
