import React from 'react'

import BrandImage from '../images/LytePop-logo-wg.png'

import './brands.scss'

export default ({data}) => {
    return (
        <section className="brands">
            <div className="wrapper">
                <a href="https://lytepop.com/" target="_blank"  rel="noopener noreferrer" target="_blank">
                    <picture> 
                        <img 
                            src={BrandImage} 
                            alt=""LytePop
                            />
                    </picture>

                    <p>7x has strategically invested in emerging brands to help facilitate in their growth and development.</p>
                </a>
            </div>
        </section>
    )
}
